import { AWSError, DynamoDB } from 'aws-sdk'
import { PromiseResult } from 'aws-sdk/lib/request'

const client = new DynamoDB.DocumentClient({
  region: process.env.REGION,
})

export const saveOnboardingCompleteEvent = async (cognitoUsername: string) => {
  const onboardingCompleted = await isOnboardingCompleted(cognitoUsername)
  if (onboardingCompleted && onboardingCompleted.length > 0 && onboardingCompleted.onboarding_completed === true) {
    return null
  }
  return await client
    .put({
      TableName: 'onboarding_tracking',
      Item: {
        cognito_id: cognitoUsername,
        onboarding_completed: true,
        completed_at: new Date().toISOString(),
      },
    })
    .promise()
}

const isOnboardingCompleted = async (cognitoUsername: string) => {
  return (
    await client
      .get({
        TableName: 'onboarding_tracking',
        Key: {
          cognito_id: cognitoUsername,
        },
      })
      .promise()
  ).Item
}

export const queryWhitelistMobile = async (
  mobileNumber: string
): Promise<PromiseResult<DynamoDB.DocumentClient.GetItemOutput, AWSError>> => {
  const phoneNumber = await client
    .get({
      TableName: 'cognito_whitelist_mobiles',
      Key: {
        mobile_number: mobileNumber,
      },
    })
    .promise()

  return phoneNumber
}

export const saveForgotPasswordToken = async (cognitoUsername: string, token: string, clientId: number) => {
  return client
    .put({
      TableName: 'forgotPassword_token',
      Item: {
        cognito_id: cognitoUsername,
        forgotPassword_token: token,
        client_id: clientId,
        dateAdded: new Date().toISOString(),
      },
    })
    .promise()
}

export const hasForgotPasswordToken = async (token: string) => {
  return (
    await client
      .get({
        TableName: 'forgotPassword_token',
        Key: {
          forgotPassword_token: token,
        },
      })
      .promise()
  ).Item
}

export const removeForgotPasswordToken = async (token: string) => {
  return client
    .delete({
      TableName: 'forgotPassword_token',
      Key: {
        forgotPassword_token: token,
      },
    })
    .promise()
}
