import Cookie from 'js-cookie'
import Query from 'models/query'
import { logger } from 'core'

const STORAGE_KEY = 'accounts-app.client-details.cashrewards'

export function storeClientDetails(query: Query) {
  logger.info(query)
  Cookie.set(STORAGE_KEY, query, { expires: 1 })
}

export function getClientDetails() {
  const clientDetails = Cookie.getJSON(STORAGE_KEY)
  Cookie.remove(STORAGE_KEY)
  return clientDetails
}
