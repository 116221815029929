import { useEffect } from 'react'
import { useRouter } from 'next/router'

// local
import useSettings from 'hooks/useSettings'
import { storeClientDetails, getClientDetails } from 'services'
import { logger } from 'core'

export function useAuthSettings({ route, cleanUrl, query }) {
  const router = useRouter()

  const { settings, saveSettings } = useSettings()

  useEffect(() => {
    if (settings) {
      return
    }

    if (!cleanUrl) {
      storeClientDetails(query)
      router.replace(route)
    } else {
      const details = getClientDetails()
      saveSettings(details)
    }
    return () => {
      logger.info('cleaned up')
    }
  }, [cleanUrl])

  return settings
}
